/* Module: S */
.film-carousel {
    overflow: hidden; // The floating slider arrows can exit the container by a couple of pixels in their hover state
    padding: 60px 0;
    &__heading {
        font-weight: normal;
        margin: 0 0 30px;
    }
    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        .slick-list {
            margin-left: -25px;
            &:before {
                background-color: $white;
                bottom: 0;
                content: '';
                position: absolute;
                top: 0;
                width: 25px;
                z-index: 2;
            }
        }
        .slick-slide {
            margin-left: 25px;
        }
        .slick-prev,
        .slick-next {
            // Positions the button
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 4;
            // Hides the button
            background-color: transparent;
            border: 0;
            color: transparent;
            font-size: 0;
            line-height: 0;
            outline: none;
            padding: 0;
            &:before {
                color: $blue;
                cursor: pointer;
                display: block;
                font-family: 'Font Awesome 5 Free';
                font-size: 64px;
                font-weight: 700;
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
            }
        }
        .slick-prev {
            left: -50px;
            transition: left $material-motion,  padding-right $material-motion;
            &:before {
                content: "\f104";
            }
            &:hover,
            &:focus {
                left: -70px;
                padding-right: 20px;
            }
        }
        .slick-next {
            right: -50px;
            transition: right $material-motion,  padding-left $material-motion;
            &:before {
                  content: "\f105";
            }
            &:hover,
            &:focus {
                right: -70px;
                padding-left: 20px;
            }
        }
    }
    &__image {
        width: 100%;
    }
    &__pills {
        &:hover,
        &:focus,
        &:active {
            color: $black;
            text-decoration: none;
        }
        padding: 10px;
        line-height: 1;
        margin-top: 20px;
        color: $black;
        display: block;
        text-align: center;
        text-transform: uppercase;
        span {
            background-color: $blue;
            border-radius: 25px;
            display: inline-block;
            padding: 12px 22px;
            min-width: 180px;
            @media only screen and (min-width: $screen-sm) {
                font-size: 18px;
                min-width: 200px;
                padding: 15px 25px;
            }
        }
    }
    @media only screen and (min-width: $screen-xs) {}
    @media only screen and (min-width: $screen-sm) {}
    @media only screen and (min-width: $screen-md) {}
    @media only screen and (min-width: $screen-lg) {}
}
