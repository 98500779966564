/* Module: S */
.cookie-info {
    min-height: pxToRem(33);
    padding: pxToRem(8) pxToRem(22);

    .container {
        flex-wrap: nowrap;
        justify-content: space-between;
    }
}

.cookie-info--disabled {
    bottom: pxToRem(-33);
}

.cookie-info__more-wrapper {
    flex-grow: 1;
}

.cookie-info__info,
.cookie-info__more-wrapper,
.cookie-info__accept-btn {
    margin: 0;
}

.cookie-info__info,
.cookie-info__more-wrapper {
    flex-basis: auto;
    text-align: left;
}

.cookie-info__accept-btn {
    height: pxToRem(30);
    line-height: pxToRem(30);
}
