/* Module: S */
.mp-menu {
    width: 250px;
    visibility: hidden;
    .mp-pushed & {
        visibility: visible;
    }
    .mp-level {
        background-color: $darkgrey;
    }
    &.mp-overlap .mp-level.mp-level-open {
        box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.3), 0 0 6px 0 rgba(0, 0, 0, 0.22);
    }
    h2,
    a {
        color: $white;
        text-shadow: none;
    }
    h2 {
        font-size: 24px;
    }
    &.mp-menu.mp-overlap h2::before {
        color: rgba($white, 0.5);
        content: attr(data-heading);
        font-size: 14px;
        margin-right: 0;
        overflow: hidden;
        pointer-events: none;
        text-overflow: ellipsis;
        transition: opacity $material-motion 0s, transform $material-motion 0.1s;
        transform: translateX(calc(50% - 70px)) translateY(150px) rotate(90deg);
        white-space: nowrap;
        width: 300px;
        background-color: #333;
        padding: 8px 0;
    }
    &.mp-overlap .mp-level.mp-level-overlay > h2::before {
        transition: opacity $material-motion 0.3s, transform $material-motion 0.3s;
        transform: translateX(calc(50% - 20px)) translateY(150px) rotate(90deg);
    }
    ol li > a,
    ul li > a {
        box-shadow: inset 0 -1px rgba($blue, 0.25);
        font-size: 16px;
        transition: all 0s ease 0s;
    }
    .mp-level > ol > li:first-child > a,
    .mp-level > ul > li:first-child > a {
        box-shadow: inset 0 -1px rgba($blue, 0.25), inset 0 1px rgba($blue, 0.25);
    }
    .mp-level.mp-level-overlay > ol > li > a,
    .mp-level.mp-level-overlay > ol > li:first-child > a,
    .mp-level.mp-level-overlay > ul > li > a,
    .mp-level.mp-level-overlay > ul > li:first-child > a {
        box-shadow: inset 0 -1px transparent;
    }
    ol li a:hover,
    ul li a:hover {
        box-shadow: inset 0 -1px rgba($blue, 0.25);
        background: initial;
    }
    @media only screen and (min-width: $screen-xs) {}
    @media only screen and (min-width: $screen-sm) {}
    @media only screen and (min-width: $screen-md) {}
    @media only screen and (min-width: $screen-lg) {}
}


// h2.icon.icon-world:before {
//     content: 'Menu';
//     transform: translateX(12px) translateY(20px) rotate(90deg) !important;
//     opacity: 0.8 !important;
// }

// color: rgba(255, 255, 255, 0.5);
// content: attr(data-heading);
// transform: translateX(calc(50% - 20px)) translateY(150px) rotate(90deg);
// width: 300px;
// margin-right: 0;
// font-size: 14px;
