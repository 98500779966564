/* Module: S */
.article-list {
    margin: 60px 0;
    &__aside-heading {
        font-size: 24px;
        font-weight: normal;
        margin: 0 0 20px;
    }
    &__row-list {
        list-style: none;
        padding: 0;
    }
    &__row-list-item {
        @media only screen and (max-width: $screen-xs-max) {
            margin-top: 20px;
            &:first-child {
                margin-top: 0;
            }
        }
        @media only screen and (min-width: $screen-sm) and (max-width: $screen-sm-max) {
            &:nth-child(2n-1) {
                clear: both;
            }
            & + .article-list__row-list-item + .article-list__row-list-item {
                margin-top: 20px;
            }
        }
    }
    &__article-link {
        color: $darkgrey;
        display: block;
        &:hover,
        &:focus {
            color: $darkgrey;
            text-decoration: none;
            .article-list__link-arrow {
                margin-left: 20px;
            }
        }
    }
    &__image-container {
        position: relative;
        display: block;
        overflow: hidden;
        @media only screen and (max-width: $screen-xs-max) {
            height: 165px;
        }
        @media only screen and (min-width: $screen-sm) {
            height: 0;
            padding: 0 0 71.43%;
        }
    }
    &__image {
        height: 100%;
        object-fit: cover;
        position: absolute;
        width: 100%;
        .no-object-fit & {
            left: 50%;
            max-width: none;
            min-width: 100%;
            transform: translateX(-50%);
            width: auto;
        }
    }
    &__article-heading {
        border-bottom: 3px solid $yellow;
        font-size: 20px;
        font-weight: normal;
        line-height: 1.15;
        margin: 15px 0;
        padding-bottom: 10px;
    }
    &__article-text {
        font-size: 14px;
    }
    &__link-arrow {
        color: $blue;
        transition: margin-left $material-motion;
    }
    @media only screen and (min-width: $screen-xs) {}
    @media only screen and (min-width: $screen-sm) {}
    @media only screen and (min-width: $screen-md) {}
    @media only screen and (min-width: $screen-lg) {}
}
