/* Module: S */
.content-text {
    line-height: 1.7;
    margin: 60px 0;
    h2 {
        font-size: 24px;
        line-height: 1.15;
    }
    h3 {
        font-size: 20px;
        line-height: 1.15;
    }
    & + .content-text .content-text__container {
        border-top: 1px solid rgba($grey, 0.3);
        padding-top: 60px;
    }
    &__wrapper {
        h2:first-child,
        h3:first-child {
            margin-top: 0;
        }
    }
    @media only screen and (min-width: $screen-md) {
        &__wrapper--two-columns {
            column-count: 2;
            column-gap: 30px;
        }
    }
    @media only screen and (min-width: $screen-xs) {}
    @media only screen and (min-width: $screen-sm) {}
    @media only screen and (min-width: $screen-md) {}
    @media only screen and (min-width: $screen-lg) {}
}
