/* Module: S */
.promoted-links {
    background-color: $darkgrey;
    margin-bottom: 15px;
    &__list {
        background-color: $white;
        box-shadow: 0 15px $blue;
        list-style: none;
        margin: 0;
        min-height: 100px;
        padding: 0;
        .no-flexboxlegacy & {
            height: 100px;
        }
        @media only screen and (min-width: $screen-sm) {
            display: flex;
        }
    }
    &__list-item {
        display: flex;
        flex-basis: 0;
        flex-grow: 1;
    }
    &__list-item + &__list-item {
        @media only screen and (max-width: $screen-xs-max) {
            border-top: solid 1px rgba($grey, 0.3);
        }
        @media only screen and (min-width: $screen-sm) {
            border-left: solid 1px rgba($grey, 0.3);
        }
    }
    &__list-link {
        align-items: center;
        color: $darkgrey;
        display: flex;
        flex-grow: 1;
        font-size: 24px;
        padding: 15px;
        &:hover,
        &:focus {
            color: $darkgrey;
            // text-decoration: none;
        }
    }
    @media only screen and (min-width: $screen-xs) {}
    @media only screen and (min-width: $screen-sm) {}
    @media only screen and (min-width: $screen-md) {}
    @media only screen and (min-width: $screen-lg) {}
}
