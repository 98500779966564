/* Module: S */
.content-header {
    // order: -1;
    &__banner {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 610px;
        position: relative;
        &:before {
            background-image: linear-gradient(to bottom, rgba($black, 0.94), transparent);
            content: '';
            display: block;
            height: 500px;
            left: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 2;
        }
        &:after {
            background-image: linear-gradient(to top, rgba($black, 0.39), transparent);
            bottom: 0;
            content: '';
            display: block;
            height: 325px;
            left: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            z-index: 2;
        }
    }
    &__banner-text-position {
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 4;
    }
    &__banner-text-wrapper {
        align-items: center;
        display: flex;
        height: 350px;
    }
    &__banner-text-heading {
        border-bottom: 3px solid $yellow;
        color: $white;
        display: inline-block;
        font-size: 48px;
        font-weight: normal;
        line-height: 1.3;
        margin: 0 0 15px;
        padding-bottom: 10px;
    }
    &__banner-text-subtitle {
        color: $white;
        font-size: 24px;
        line-height: 1.25;
    }
    @media only screen and (min-width: $screen-xs) {}
    @media only screen and (min-width: $screen-sm) {}
    @media only screen and (min-width: $screen-md) {}
    @media only screen and (min-width: $screen-lg) {}
}
