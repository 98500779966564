$black: #000;
$white: #fff;

$offwhite: #fffcfc;
$lightgrey: #ececec;
$grey: #979797;
$darkgrey: #4a4a4a;
$blue: #3ab5e1;
$darkerblue: #1c729e; // this blue on lightgrey gives a WCAG AA pass
$blue-dark: darken(#3ab5e1, 21%);// darkened for accessiblity contrast
$yellow: #f5a623;


// Custom responsive points
$screen-film-poster-list: 544px;
$screen-film-poster-list-max: ($screen-film-poster-list - 1);


// Base font
$base-font-scale: 1;  // The font scale multiplier (e.g. 2 = 32px/2em/2rem)
$base-font-pixel: 16; // Used for font size calculations & conversions
$base-font-colour: $darkgrey;

// Selection highlighting
$content-selection-highlight: #b3d4fc;

$material-motion: 0.2s cubic-bezier(0.4, 0.0, 0.2, 1);
