/* Module: S */
.latest-news {
    $grid-gap: 25px;
    background-color: $lightgrey;
    padding: 60px 0;
    &__section-heading {
        font-size: 36px;
        font-weight: normal;
        margin: 0 0 30px;
    }
    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        @media only screen and (min-width: $screen-sm) {
            display: grid;
            grid-gap: $grid-gap;
            grid-template-columns: 1fr;
            grid-template-rows: 165px 165px 165px 165px 165px 165px;
        }
        @media only screen and (min-width: $screen-md) {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 165px 165px 165px;
        }
    }
    &__list-item {
        background: $white;
        .latest-news__link {
            color: $darkgrey;
            display: flex;
            height: 100%;
            overflow: hidden;
            &:hover,
            &:focus {
                color: $darkgrey;
                text-decoration: none;
                .latest-news__link-arrow {
                    margin-right: 0;
                }
            }
        }
        .latest-news__image-container {
            position: relative;
            display: block;
            height: 100%;
            width: 165px;
            overflow: hidden;
        }
        .latest-news__image {
            height: 100%;
            object-fit: cover;
            position: absolute;
            width: 100%;
            .no-object-fit & {
                left: 50%;
                max-width: none;
                min-width: 100%;
                transform: translateX(-50%);
                width: auto;
            }
        }
        .latest-news__text-container {
            padding: 15px 65px 15px 15px;
            position: relative;
            width: calc(100% - 165px);
        }
        .latest-news__heading {
            border-bottom: 3px solid $yellow;
            font-size: 20px;
            font-weight: normal;
            line-height: 1.15;
            margin: 0 0 10px;
            padding-bottom: 10px;
        }
        .latest-news__date {
            display: block;
            font-size: 14px;
            font-style: italic;
            margin-bottom: 4px;
        }
        .latest-news__text {
            font-size: 14px;
        }
        .latest-news__link-arrow {
            bottom: 20px;
            color: $blue;
            margin-right: 20px;
            position: absolute;
            right: 15px;
            transition: margin-right $material-motion;
        }
        @media only screen and (max-width: $screen-xs-max) {
            margin-top: $grid-gap;
            &:first-child {
                margin-top: 0;
            }
            .latest-news__link {
                flex-direction: column;
            }
            .latest-news__image-container {
                height: 165px;
                width: 100%;
            }
            .latest-news__text-container {
                min-height: 165px;
                width: 100%;
            }
        }
    }
    &__list-item:first-child {
        grid-area: 1 / 1 / span 3 / 2;
        .latest-news__link {
            flex-direction: column;
        }
        .latest-news__image-container {
            // height: 355px;
            width: 100%;
        }
        .latest-news__text-container {
            height: calc(100% - 355px);
            width: 100%;
        }
    }
    .no-cssgrid & {
        &__list {
            display: -ms-grid;
            margin: (0 - ($grid-gap / 2));
            -ms-grid-columns: 1fr 1fr;
            -ms-grid-rows: (165px + $grid-gap) (165px + $grid-gap) (165px + $grid-gap);
        }
        &__list-item {
            margin: ($grid-gap / 2);
            &:nth-child(1) {
                -ms-grid-row: 1;
                -ms-grid-column: 1;
                -ms-grid-row-span: 3;
                -ms-grid-column-span: 1;
            }
            &:nth-child(2) {
                -ms-grid-row: 1;
                -ms-grid-column: 2;
                -ms-grid-row-span: 1;
                -ms-grid-column-span: 1;
            }
            &:nth-child(3) {
                -ms-grid-row: 2;
                -ms-grid-column: 2;
                -ms-grid-row-span: 1;
                -ms-grid-column-span: 1;
            }
            &:nth-child(4) {
                -ms-grid-row: 3;
                -ms-grid-column: 2;
                -ms-grid-row-span: 1;
                -ms-grid-column-span: 1;
            }
        }
        // &__text-container {
        //     max-width: 100%;
        // }
    }
    @media only screen and (min-width: $screen-xs) {}
    @media only screen and (min-width: $screen-sm) {}
    @media only screen and (min-width: $screen-md) {}
    @media only screen and (min-width: $screen-lg) {}
}
