/* Module: S */
.tile-links {
    $grid-gap: 25px;
    padding: 60px 0;
    &__intro-wrapper {
        padding-bottom: 60px;
        text-align: center;
    }
    &__intro-heading {
        font-size: 36px;
        font-weight: normal;
        margin-top: 0;
    }
    &__intro-text {
        font-size: 20px;
    }
    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        @media only screen and (min-width: $screen-sm) {
            display: grid;
            grid-gap: $grid-gap;
            grid-template-columns: 1fr;
            grid-template-rows: 300px 300px 300px 300px 220px;
        }
        @media only screen and (min-width: $screen-sm) {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 300px 300px 220px 220px;
        }
        @media only screen and (min-width: $screen-md) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-rows: 300px 300px 220px;
        }
    }
    @media only screen and (max-width: $screen-xs-max) {
        &__list-item {
            margin-top: $grid-gap;
            &:first-child {
                margin-top: 0;
            }
        }
    }
    &__list-item:nth-child(1) {

        @media only screen and (min-width: $screen-sm) {
            grid-area: 1 / 1 / span 2 / 2;
        }
    }
    &__list-item:nth-child(2) {

        @media only screen and (min-width: $screen-sm) {
            grid-area: 1 / 2 / 1 / span 2;
        }
    }
    &__list-item:nth-child(3) {

        @media only screen and (min-width: $screen-sm) {
            grid-area: 2 / 2 / 2 / span 2;
        }
        .tile-links__link {
            flex-direction: row;
        }
        @media only screen and (min-width: $screen-sm) {
            .tile-links__image-container {
                height: 100%;
                width: calc(50% + #{$grid-gap / 2});
            }
            .tile-links__text-container {
                width: calc(50% - #{$grid-gap / 2});
            }
        }
        .tile-links__link--text-at-start {
            align-items: flex-end;
            flex-direction: row-reverse;
        }
    }
    &__list-item:nth-child(4) {

        @media only screen and (min-width: $screen-sm) {
            grid-area: 3 / 3 / span 2 / 4;
        }
        @media only screen and (min-width: $screen-md) {
            grid-area: 1 / 4 / span 2 / 4;
        }
    }
    .no-cssgrid & {
        &__list {
            display: -ms-grid;
            margin: (0 - ($grid-gap / 2));
            -ms-grid-columns: 1fr 1fr 1fr 1fr;
            -ms-grid-rows: (300px + $grid-gap) (300px + $grid-gap) (220px + $grid-gap);
        }
        &__list-item {
            margin: ($grid-gap / 2);
            &:nth-child(1) {
                -ms-grid-row: 1;
                -ms-grid-column: 1;
                -ms-grid-row-span: 2;
                -ms-grid-column-span: 1;
            }
            &:nth-child(2) {
                -ms-grid-row: 1;
                -ms-grid-column: 2;
                -ms-grid-row-span: 1;
                -ms-grid-column-span: 2;
            }
            &:nth-child(3) {
                -ms-grid-row: 2;
                -ms-grid-column: 2;
                -ms-grid-row-span: 1;
                -ms-grid-column-span: 2;
            }
            &:nth-child(4) {
                -ms-grid-row: 1;
                -ms-grid-column: 4;
                -ms-grid-row-span: 2;
                -ms-grid-column-span: 1;
            }
            &:nth-child(5) {
                -ms-grid-row: 3;
                -ms-grid-column: 1;
                -ms-grid-row-span: 1;
                -ms-grid-column-span: 1;
            }
            &:nth-child(6) {
                -ms-grid-row: 3;
                -ms-grid-column: 2;
                -ms-grid-row-span: 1;
                -ms-grid-column-span: 1;
            }
            &:nth-child(7) {
                -ms-grid-row: 3;
                -ms-grid-column: 3;
                -ms-grid-row-span: 1;
                -ms-grid-column-span: 1;
            }
            &:nth-child(8) {
                -ms-grid-row: 3;
                -ms-grid-column: 4;
                -ms-grid-row-span: 1;
                -ms-grid-column-span: 1;
            }
        }
        &__text-container {
            max-width: 100%;
        }
    }
    &__link--full-image,
    &__list-item:nth-child(5) .tile-links__link,
    &__list-item:nth-child(6) .tile-links__link,
    &__list-item:nth-child(7) .tile-links__link,
    &__list-item:nth-child(8) .tile-links__link {
        /* START this block of CSS is also used just below */
        .tile-links__image-container {
            height: 100%;
            &:before {
                background-image: linear-gradient(to bottom, transparent, rgba($black, 0.94));
                bottom: 0;
                content: '';
                display: block;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 2;
            }
            & + .tile-links__text-container {
                bottom: 0;
                color: $white;
                left: 0;
                position: absolute;
                z-index: 4;
            }
        }
        &.tile-links__link--text-at-start .tile-links__image-container {
            &:before {
                background-image: linear-gradient(to top, transparent, rgba($black, 0.94));
            }
            & + .tile-links__text-container {
                bottom: auto;
                top: 0;
            }
        }
        /* END this block of CSS is also used just below */
    }
    &__link {
        border: 1px solid rgba($grey, 0.3);
        color: $darkgrey;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        overflow: hidden;
        position: relative;
        &:hover,
        &:focus {
            color: $darkgrey;
            text-decoration: none;
            .tile-links__link-arrow {
                margin-left: 20px;
            }
        }
        @media only screen and (max-width: $screen-xs-max) {
            min-height: 220px;
            /* START this block of CSS is also used just above */
            .tile-links__image-container {
                height: 100%;
                &:before {
                    background-image: linear-gradient(to bottom, transparent, rgba($black, 0.94));
                    bottom: 0;
                    content: '';
                    display: block;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: 2;
                }
                & + .tile-links__text-container {
                    bottom: 0;
                    color: $white;
                    left: 0;
                    position: absolute;
                    z-index: 4;
                }
            }
            &.tile-links__link--text-at-start .tile-links__image-container {
                &:before {
                    background-image: linear-gradient(to top, transparent, rgba($black, 0.94));
                }
                & + .tile-links__text-container {
                    bottom: auto;
                    top: 0;
                }
            }
            /* END this block of CSS is also used just above */
            // The following generates a triple .tile-links__link rule to override other CSS without using !important
            &.tile-links__link.tile-links__link {
                .tile-links__image-container {
                    position: absolute;
                    &:before {
                        background-color: rgba($black, 0.6667);
                        background-image: none;
                    }
                    & + .tile-links__text-container {
                        position: relative;
                    }
                }
            }
        }
        @media only screen and (min-width: $screen-sm) {
            align-items: flex-end;
        }
    }
    &__image-container {
        position: relative;
        display: block;
        height: calc(50% + #{$grid-gap / 2});
        width: 100%;
        overflow: hidden;
    }
    &__image {
        height: 100%;
        object-fit: cover;
        position: absolute;
        width: 100%;
        .no-object-fit & {
            left: 50%;
            max-width: none;
            min-width: 100%;
            transform: translateX(-50%);
            width: auto;
        }
    }
    &__text-container {
        margin-top: auto;
        padding: 15px;
    }
    &__heading {
        border-bottom-style: solid;
        border-image: linear-gradient(to right, $yellow 0%, $yellow calc(100% - 30px), transparent calc(100% - 30px), transparent 100%);
        border-image-slice: 1;
        border-width: 0 0 3px 0;
        font-size: 24px;
        font-weight: normal;
        margin: 0 0 15px;
        padding-bottom: 10px;
    }
    &__text {
        font-size: 14px;
    }
    &__link-arrow {
        color: $blue;
        transition: margin-left $material-motion;
    }
    &__link--text-at-start {
        align-items: flex-start;
        flex-direction: column-reverse;
        .tile-links__text-container {
            margin-top: 0;
        }
    }
    &__link--blue-background {
        background-color: $blue;
        border: none;
        color: $black;
        &:hover,
        &:focus,
        &:active {
            color: $black;
        }
        .tile-links__heading {
            border-image: linear-gradient(to right, $white 0%, $white calc(100% - 30px), transparent calc(100% - 30px), transparent 100%);
            border-image-slice: 1;
        }
        .tile-links__link-arrow {
            color: $white;
        }
    }
    @media only screen and (min-width: $screen-xs) {}
    @media only screen and (min-width: $screen-sm) {}
    @media only screen and (min-width: $screen-md) {}
    @media only screen and (min-width: $screen-lg) {}
}
