/* Module: S */
.content-feature {
    margin: 60px 0;
    &__text-container {
        line-height: 1.7;
        padding-bottom: 40px;
        @media only screen and (min-width: $screen-md) {
            min-height: 400px;
        }
        h3 {
            border-bottom: 3px solid $yellow;
            font-size: 22px;
            font-weight: normal;
            line-height: 1.4;
            padding-bottom: 10px;
            &:first-child {
                margin-top: 0;
            }
        }
        blockquote {
            border: none;
            font-size: 16px;
            font-style: italic;
            margin: 30px 0;
            padding: 0;
            &:before {
                content: normal;
            }
            p {
                margin-bottom: 20px;
                &:first-child:before {
                    content: '\201C'
                }
                &:last-child:after {
                    content: '\201D'
                }
            }
        }
    }
    @media only screen and (min-width: $screen-md) {
        &__background-image-container {
            box-shadow: inset 0 0 19px 0 rgba(0, 0, 0, 0.3), inset 0 0 6px 0 rgba(0, 0, 0, 0.22);
            left: ($grid-gutter-width / 2);
            right: ($grid-gutter-width / 2);
            position: absolute;
            &:before {
                background-image: linear-gradient(to left, rgba($black, 0.48), transparent 300px);
                bottom: 0;
                box-shadow: inset 0 0 19px 0 rgba(0, 0, 0, 0.3), inset 0 0 6px 0 rgba(0, 0, 0, 0.22);
                content: '';
                display: block;
                left: 0;
                pointer-events: none;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 2;
            }
        }
        &__background-image {
            width: 100%;
        }
    }
    &__subtitle {
        font-weight: 500;
    }
    &__quote-author {
        font-weight: 500;
    }
    &__foreground-image-container {
        // box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.3), 0 0 6px 0 rgba(0, 0, 0, 0.22);
        position: relative;
        z-index: 4
    }
    @media only screen and (min-width: $screen-xs) {}
    @media only screen and (min-width: $screen-sm) {}
    @media only screen and (min-width: $screen-md) {}
    @media only screen and (min-width: $screen-lg) {}
}
