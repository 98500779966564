/* Module: S */
.coming-soon-wrapper #page-wrapper,
.coming-soon-wrapper .main {
    height: 100%;
}
.coming-soon {
    height: 100%;
    // height: 675px;
    @media only screen and (min-width: $screen-sm) {
        // height: 875px;
    }
    &__carousel {
        height: 100%;
        list-style: none;
        margin: 0;
        padding: 0;
    }
    &__carousel-item {
        background-position: 75% center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        // Used to be part of .homepage-carousel properties so it only needed to be rendered once, but there were z-ndex issues with the link text
        position: relative;
        &:before {
            background-color: rgba($black, 0.12);
            content: '';
            display: block;
            height: 100%;
            left: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 2;
        }
    }
    &__slide-wrapper {
        align-items: center;
        display: flex;
        height: 100%;
        padding: 200px 0 75px 0;
        @media only screen and (max-width: $screen-xs-max) {
            > .container {
                flex-grow: 1;
            }
        }
    }
    &__link {
        color: $white;
        display: block;
        padding-bottom: 100px;
        position: relative;
        z-index: 4;
        &:hover,
        &:focus {
            color: $white;
            text-decoration: none;
        }
    }
    &__heading {
        font-size: 34px;
        font-weight: 500;
        text-transform: uppercase;
        @media only screen and (min-width: $screen-sm) {
            font-size: 64px;
            &:after {
                font-size: 18px;
                padding: 15px 25px;
                min-width: 200px;
            }
        }
    }
}
