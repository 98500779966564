/* Module: S */
.header-nav {
    background-color: rgba($black, 0.3); // rgba($offwhite, 0.06);
    &__top-level-list {
        display: flex;
        justify-content: space-between;
        list-style: none;
        margin: 0;
        min-height: 70px;
        padding: 0;
        position: relative;
        .no-flexboxlegacy & {
            height: 70px;
        }
    }
    &__top-level-list-item {
        display: flex;
    }
    &__top-level-list-link {
        align-items: center;
        color: $white;
        display: flex;
        flex-grow: 1;
        padding: 15px 0;
        &:hover,
        &:focus {
            color: $white;
            text-decoration: none;
        }
        // The following rules are separate because IE and Edge fails the whole rule if it contains :focus-within
        .header-nav__top-level-list-item--hover > & {
            box-shadow: inset 0 -4px $blue;
        }
        .header-nav__top-level-list-item:focus-within > & {
            box-shadow: inset 0 -4px $blue;
        }
        // &:focus-within {
        //     //background: red;
        //     + .header-nav__mega-menu {
        //         display: block;
        //     }
        // }
    }
    &__mega-menu {
        display: none;
        left: 0;
        min-height: 165px;
        padding: 30px 0 40px;
        position: absolute;
        right: 0;
        top: 100%;
        z-index: 1;
        &,
        &:before,
        &:after {
            background-color: $black;
            border-color: $blue;
            border-style: solid;
            border-width: 1px 0 15px 0;
            transform: translate3d(0,0,0);
        }

        &:before,
        &:after {
            bottom: -15px;
            content: '';
            position: absolute;
            top: -1px;
            width: 50vw;
            z-index: -1;

        }
        &:before {
            right: 50%;

        }
        &:after {
            // Without the calc and box-shadow here, a horizontal scrollbar appears when the mega menu opens. It's an issue when using vw units.
            left: calc(50% - 8px);
            box-shadow: 16px -7px 0 -8px $black, 8px 0 0 0 $blue;
        }
        // The following rules are separate because IE and Edge fails the whole rule if it contains :focus-within
        .header-nav__top-level-list-item--hover > & {
            display: block;
        }
        .header-nav__top-level-list-item:focus-within > & {
            display: block;
        }
        // .header-nav__top-level-list-item--hover > &,
        // &.active {
        //     display: block;
        // }
    }
    &__mega-menu-column-wrapper {
        padding-right: 49px;
        .showmore {
            position: relative;
            width: 100%;
            margin-top:20px;
            text-align: right;
            border-top: 1px solid $blue;
            a {
                margin-top:7px;
                color: $blue;
                font-size: 14px;
                display: block;
                .header-nav__link-arrow {
                    &:before {
                        position: absolute;
                        top: 60%;
                        transform: translateY(-50%);
                    }
                }
                &:hover, &:focus {
                    text-decoration: none;
                    color: $blue;
                    .header-nav__link-arrow {
                        padding-left: 35px;
                    }
                }
            }
        }
    }
    &__mega-menu-section-heading {
        border-bottom: 3px solid $blue;
        font-size: 24px;
        font-weight: normal;
        margin: 0 0 10px;
        padding-bottom: 10px;
    }
    &__mega-menu-section-text {
        color: $white;
        font-size: 14px;
    }
    &__mega-menu-list-heading {
        border-bottom: 3px solid $blue;
        font-size: 16px;
        font-weight: normal;
        margin: 9px 0 10px;
        padding-bottom: 10px;
    }
    &__mega-menu-list {
        font-size: 14px;
        list-style: none;
        margin: 0;
        padding: 0;
    }
    &__mega-menu-list-item {
        margin-bottom: 10px;
    }
    &__mega-menu-link {
        color: $white;
        display: block;
        position: relative;
        &:hover,
        &:focus {
            color: $white;
            text-decoration: none;
            .header-nav__link-arrow {
                padding-left: 35px;
            }
        }
    }
    &__link-arrow {
        bottom: 0;
        color: $blue;
        left: 100%;
        position: absolute;
        padding-left: 15px;
        top: 0;
        transition: padding-left $material-motion;
        &:before {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    @media only screen and (min-width: $screen-xs) {}
    @media only screen and (min-width: $screen-sm) {}
    @media only screen and (min-width: $screen-md) {}
    @media only screen and (min-width: $screen-lg) {}
}

.mega-menu--toggle {
    position: absolute;
    top: 10px;
    right: 0;
    padding: 5px 10px;
    &:focus,
    &:hover,
    &:active {
        color: $blue;
        text-decoration: none;
    }
}
