/* Module: S */
//Header search box styling
.header-search .input-group {
  border-bottom: 2px solid #f3a633;
  background-color: rgba(0, 0, 0, 0.2);
}
.header-search .form-control, .header-search .btn {
  background: transparent;
  border: none;
  color: #fff;
  box-shadow: none;
  text-shadow: none;
  font-size: 16px;
  padding-top: 8px;
}
.header-search .form-control {
  height: 44px;
}
.header-search ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    #fff !important;
  font-weight: 500;
}
.header-search :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color:    #fff !important;
  font-weight: 500;
  opacity:  1;
}
.header-search ::-moz-placeholder { /* Mozilla Firefox 19+ */
  color:    #fff !important;
  font-weight: 500;
  opacity:  1;
}
.header-search :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:    #fff !important;
  font-weight: 500;
}
.header-search ::-ms-input-placeholder { /* Microsoft Edge */
  color:    #fff !important;
  font-weight: 500;
}
.header-search ::placeholder { /* Most modern browsers support this now. */
  color:    #fff !important;
  font-weight: 500;
}
@media (max-width: 768px) {
  .header__container {
    min-height: 0;
  }
  .search-container {
    padding-top: 0;
  }
  .search-container .nav-form {
    border: 0;
    box-shadow: none;
  }
}

//Search results page styling
.search-results {
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  color: #4A4A4A;
  font-size: 14px;
}

.search-results .item-title {
  color: #4A4A4A;
  font-size: 18px;
  font-weight: normal;
}

.search-results h1 {
  font-size: 32px;
  line-height: 38px;
  margin-top: 35px;
  font-weight: normal;
}

.search-results .sub-title {
  font-size: 18px;
  margin-bottom: 24px;
}

.search-results h3 {
  margin-bottom: 13px;
}

.search-results .search-header {
  border-bottom: 2px solid #F5A623;
}

.search-results .pagination {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 100px;
  margin-bottom: 50px;
  font-size: 18px;
}

.search-results .pagination .prev svg {
  margin-right: 11px;
}

.search-results .pagination .next svg {
  margin-left: 11px;
}

.search-results .pagination span {
  padding: 7px;
  display: inline-block;
}

.search-results .pagination span.active {
  border-bottom: 1px solid #1C729E;
}

@media (max-width: 768px) {
  .search-results .filter-container {
    flex-direction: column;
  }

  .search-results .filter-container span {
    padding: 10px 0;
  }
}