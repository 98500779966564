/* Module: S */
.footer-toolbar {
    // .homepage & {
    //     background-color: $lightgrey;
    // }
    &__container {
        align-items: center;
        background-color: $darkgrey;
        display: flex;
        justify-content: space-between;
        padding: 10px 15px;
    }
    &__social-container {
        align-items: center;
        display: flex;
    }
    &__social-text {
        color: $white;
        margin: 0;
    }
    &__social-list {
        align-items: center;
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
    }
    &__social-list-item {
        margin-left: 15px;
        @media only screen and (max-width: $screen-xs-max) {
            &:first-child {
                margin-left: 0;
            }
        }
    }
    &__social-list-link,
    &__back-to-top {
        background-color: $blue;
        border-radius: 3px;
        color: transparent;
        display: block;
        font-size: 0;
        height: 30px;
        line-height: 0;
        padding: 5px;
        width: 30px;
    }
    &__fa-icon {
        fill: $white;
        height: 100%;
        width: 100%;
    }
    // &__back-to-top {
    //     flex-grow: 1;
    //     text-align: right;
    // }
    @media only screen and (min-width: $screen-xs) {}
    @media only screen and (min-width: $screen-sm) {}
    @media only screen and (min-width: $screen-md) {}
    @media only screen and (min-width: $screen-lg) {}
}
