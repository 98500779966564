/* Module: S */
.landing-news {
  $grid-gap: 25px;
  // If this madule is directly after the breadcrumbs module in the DOM…
  // It's used to hide/cover the breadcrumbs bottom border
  .breadcrumbs + & {
      margin-top: -1px;
      position: relative;
  }
  background-color: $lightgrey;
  padding: 60px 0;
  &__section-heading {
    font-size: 36px;
    font-weight: normal;
    margin: 0 0 30px;
  }
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    @media only screen and (min-width: $screen-sm) {
      display: grid;
      grid-gap: $grid-gap;
      grid-template-columns: 1fr;
      grid-auto-rows: 165px;
    }
    @media only screen and (min-width: $screen-md) {
      grid-template-columns: 1fr 1fr;
    }
  }
  &__list-item {
    background: $white;
    .landing-news__link {
      color: $darkgrey;
      display: flex;
      height: 100%;
      overflow: hidden;
      &:hover,
      &:focus {
        color: $darkgrey;
        text-decoration: none;
        .landing-news__link-arrow {
          margin-right: 0;
        }
      }
    }
    .landing-news__image-container {
      position: relative;
      display: block;
      height: 100%;
      width: 165px;
      overflow: hidden;
    }
    .landing-news__image {
      height: 100%;
      object-fit: cover;
      position: absolute;
      width: 100%;
      .no-object-fit & {
        left: 50%;
        max-width: none;
        min-width: 100%;
        transform: translateX(-50%);
        width: auto;
      }
    }
    .landing-news__text-container {
      padding: 15px 65px 15px 15px;
      position: relative;
      width: calc(100% - 165px);
    }
    .landing-news__heading {
      border-bottom: 3px solid $yellow;
      font-size: 20px;
      font-weight: normal;
      line-height: 1.15;
      margin: 0 0 10px;
      padding-bottom: 10px;
    }
    .landing-news__date {
      display: block;
      font-size: 14px;
      font-style: italic;
      margin-bottom: 4px;
    }
    .landing-news__text {
      font-size: 14px;
    }
    .landing-news__link-arrow {
      bottom: 20px;
      color: $blue;
      margin-right: 20px;
      position: absolute;
      right: 15px;
      transition: margin-right $material-motion;
    }
    @media only screen and (max-width: $screen-xs-max) {
      margin-top: $grid-gap;
      &:first-child {
        margin-top: 0;
      }
      .landing-news__link {
        flex-direction: column;
      }
      .landing-news__image-container {
        height: 165px;
        width: 100%;
      }
      .landing-news__text-container {
        min-height: 165px;
        width: 100%;
      }
    }
  }
  &__list-item:first-child {
    grid-area: 1 / 1 / span 3 / 2;
    .landing-news__link {
      flex-direction: column;
    }
    .landing-news__image-container {
      // height: 355px;
      width: 100%;
    }
    .landing-news__text-container {
      height: calc(100% - 355px);
      width: 100%;
    }
  }
  .no-cssgrid & {
    &__list {
      display: -ms-grid;
      margin: (0 - ($grid-gap / 2));
      -ms-grid-columns: 1fr 1fr;
      -ms-grid-rows: (165px + $grid-gap) (165px + $grid-gap) (165px + $grid-gap);
      @media only screen and (min-width: $screen-sm) {
        //display: grid;
        //grid-gap: $grid-gap;
        -ms-grid-columns: 1fr;
        -ms-grid-rows: (165px + $grid-gap) (165px + $grid-gap) (165px + $grid-gap) (165px + $grid-gap) (165px + $grid-gap) (165px + $grid-gap) (165px + $grid-gap) (165px + $grid-gap) (165px + $grid-gap);
      }
      @media only screen and (min-width: $screen-md) {
        -ms-grid-columns: 1fr 1fr;
        -ms-grid-rows: (165px + $grid-gap) (165px + $grid-gap) (165px + $grid-gap) (165px + $grid-gap) (165px + $grid-gap) (165px + $grid-gap);
      }
    }
    &__list-item {
      margin: ($grid-gap / 2);
      &:nth-child(1) {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        -ms-grid-row-span: 3;
        -ms-grid-column-span: 1;
      }
      &:nth-child(2) {
        -ms-grid-row: 1;
        -ms-grid-column: 2;
        -ms-grid-row-span: 1;
        -ms-grid-column-span: 1;
      }
      &:nth-child(3) {
        -ms-grid-row: 2;
        -ms-grid-column: 2;
        -ms-grid-row-span: 1;
        -ms-grid-column-span: 1;
      }
      &:nth-child(4) {
        -ms-grid-row: 3;
        -ms-grid-column: 2;
        -ms-grid-row-span: 1;
        -ms-grid-column-span: 1;
      }
      &:nth-child(5) {
        -ms-grid-row: 4;
        -ms-grid-column: 1;
        -ms-grid-row-span: 1;
        -ms-grid-column-span: 1;
      }
      &:nth-child(6) {
        -ms-grid-row: 4;
        -ms-grid-column: 2;
        -ms-grid-row-span: 1;
        -ms-grid-column-span: 1;
      }
      &:nth-child(7) {
        -ms-grid-row: 5;
        -ms-grid-column: 1;
        -ms-grid-row-span: 1;
        -ms-grid-column-span: 1;
      }
      &:nth-child(8) {
        -ms-grid-row: 5;
        -ms-grid-column: 2;
        -ms-grid-row-span: 1;
        -ms-grid-column-span: 1;
      }
      &:nth-child(9) {
        -ms-grid-row: 6;
        -ms-grid-column: 1;
        -ms-grid-row-span: 1;
        -ms-grid-column-span: 1;
      }
      &:nth-child(10) {
        -ms-grid-row: 6;
        -ms-grid-column: 2;
        -ms-grid-row-span: 1;
        -ms-grid-column-span: 1;
      }
    }
    // &__text-container {
    //     max-width: 100%;
    // }
  }
  &__pagination {
    list-style: none;
    padding: 50px 0 0 0;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.15;
    color: $yellow;
    text-align: center;
    li {
      min-width: 20px;
      display: inline-block;
      a {
        position: relative;
        color: $darkgrey;
        font-weight: normal;
        border-bottom: 3px solid transparent;
        &:hover, &:focus {
          text-decoration: none;
          border-bottom: 3px solid $yellow;
        }
      }
      &.pagination_next {
        position: relative;
        .landing-news__link-arrow {
          font-size: 12px;
          bottom: 6px;
          color: #3ab5e1;
          pointer-events: none;
          position: absolute;
          margin-right: 20px;
          right: -38px;
          transition: margin-right 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        }
        &:hover, &:focus {
          .landing-news__link-arrow {
            margin-right: 0px;
          }
        }
      }
      &.pagination_prev {
        position: relative;
        //margin-right: 20px;
        .landing-news__link-arrow {
          font-size: 12px;
          bottom: 6px;
          color: #3ab5e1;
          pointer-events: none;
          position: absolute;
          margin-left: 20px;
          left: -38px;
          transition: margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        }
        &:hover, &:focus {
          .landing-news__link-arrow {
            margin-left: 0px;
          }
        }
      }
    }
  }
  @media only screen and (min-width: $screen-xs) {
  }
  @media only screen and (min-width: $screen-sm) {
  }
  @media only screen and (min-width: $screen-md) {
  }
  @media only screen and (min-width: $screen-lg) {
  }
}
