/* Module: S */
.social-feeds {
    $grid-gap: 10px;
    background-color: $lightgrey;
    padding: 60px 0 0;
    @media only screen and (min-width: $screen-md) {
        padding: 60px 0;
    }
    &__heading {
        font-size: 24px;
        font-weight: normal;
        margin: 0 0 20px;
    }
    &__instagram-list {
        display: grid;
        grid-gap: $grid-gap;
        grid-template-columns: 1fr 1fr;
        list-style: none;
        margin: 0 0 30px;
        padding: 0;
        @media only screen and (min-width: $screen-xs) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @media only screen and (min-width: $screen-md) {
            margin: 0;
        }
        .no-cssgrid & {
            display: -ms-grid;
            margin: (0 - ($grid-gap / 2)) (0 - ($grid-gap / 2)) (30px + (0 - ($grid-gap / 2))) (0 - ($grid-gap / 2));
            -ms-grid-columns: 1fr 1fr;
            .social-feeds__intagram-list-item {
                margin: ($grid-gap / 2);
                -ms-grid-row-span: 1;
                -ms-grid-column-span: 1;
                &:nth-child(1) {
                    -ms-grid-row: 1;
                    -ms-grid-column: 1;
                }
                &:nth-child(2) {
                    -ms-grid-row: 1;
                    -ms-grid-column: 2;
                }
                &:nth-child(3) {
                    -ms-grid-row: 2;
                    -ms-grid-column: 1;
                }
                &:nth-child(4) {
                    -ms-grid-row: 2;
                    -ms-grid-column: 2;
                }
                &:nth-child(5) {
                    -ms-grid-row: 3;
                    -ms-grid-column: 1;
                }
                &:nth-child(6) {
                    -ms-grid-row: 3;
                    -ms-grid-column: 2;
                }
            }
            @media only screen and (min-width: $screen-xs) {
                -ms-grid-columns: 1fr 1fr 1fr;
                .social-feeds__intagram-list-item {
                    &:nth-child(1) {
                        -ms-grid-row: 1;
                        -ms-grid-column: 1;
                    }
                    &:nth-child(2) {
                        -ms-grid-row: 1;
                        -ms-grid-column: 2;
                    }
                    &:nth-child(3) {
                        -ms-grid-row: 1;
                        -ms-grid-column: 3;
                    }
                    &:nth-child(4) {
                        -ms-grid-row: 2;
                        -ms-grid-column: 1;
                    }
                    &:nth-child(5) {
                        -ms-grid-row: 2;
                        -ms-grid-column: 2;
                    }
                    &:nth-child(6) {
                        -ms-grid-row: 2;
                        -ms-grid-column: 3;
                    }
                }
            }
        }
    }
    &__instagram-link {
        display: block;
        height: 0;
        overflow: hidden;
        padding-top: 100%;
        position: relative;
        &:focus {
            outline: 5px auto -webkit-focus-ring-color;
        }
    }
    &__instagram-img {
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
    }
    &__twitter-list {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    &__twitter-list-item {
        font-size: 14px;
        margin-bottom: 20px;
    }
    @media only screen and (min-width: $screen-xs) {}
    @media only screen and (min-width: $screen-sm) {}
    @media only screen and (min-width: $screen-md) {}
    @media only screen and (min-width: $screen-lg) {}
}
