/* Module: S */

.breadcrumbs {
    &__list {
        border-bottom: 1px solid rgba($grey, 0.3);
        list-style: none;
        margin: 0;
        padding: 10px 0;
        // The following two rules allow the border to be covered/hidden with "margin-top: -1px" when it's not required
        position: relative;
        //z-index: -1; // moved the rules to the specific page so that breadcrumb doesnt need to be -1 z-index as that casuses issues clicking on them 
    }
    &__list-item {
        display: inline-block;
    }
    &__list-item + &__list-item:before {
        content: '>';
        display: inline-block;
        font-size: 14px;
        margin: 0 8px 0 4px;
        padding: 10px 0;
    }
    &__list-link {
        color: $blue;
        display: inline-block;
        font-size: 14px;
        padding: 10px 0;
        text-decoration: underline;
    }
    &__list-text {
        display: inline-block;
        font-size: 14px;
        padding: 10px 0;
    }
    @media only screen and (min-width: $screen-xs) {}
    @media only screen and (min-width: $screen-sm) {}
    @media only screen and (min-width: $screen-md) {}
    @media only screen and (min-width: $screen-lg) {}
}
