/* Module: S */
.cookie-info {
    position: fixed;
    top: 0;
    width: 100%;
    overflow: hidden;
    transition: .2s all ease;
    background-color: $grey;
    z-index: 999;
    font-size: 0.9em;
    //text-transform: uppercase;
    padding: pxToRem(10) pxToRem(22);

    .container {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
}

.cookie-info__info,
.cookie-info__more-wrapper,
.cookie-info__accept-btn {
    margin: pxToRem(5) 0;
}



.cookie-info__accept-btn {
    color: $darkgrey;
    background: $white;
    text-transform: uppercase;
    padding: 0 pxToRem(16);
    height: pxToRem(38);
    line-height: pxToRem(38);

    &:focus {
        outline: none;
        outline-offset: 1px;
    }
    &:hover {
        background-color: $lightgrey !important;
        color: $black;
    }
}

