/* Module: S */
.landing-tiles {
    $grid-gap: 25px;
    padding: 60px 0;
    &__intro-wrapper {
        padding-bottom: 60px;
        text-align: center;
    }
    &__intro-heading {
        font-size: 36px;
        font-weight: normal;
        margin-top: 0;
    }
    &__intro-text {
        font-size: 20px;
    }
    &__section-heading {
        font-size: 24px;
        font-weight: normal;
        margin: 0 0 20px;
    }
    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        @media only screen and (min-width: $screen-sm) {
            display: grid;
            grid-gap: $grid-gap;
            grid-template-columns: 1fr 1fr 1fr;
            grid-auto-rows: 220px;
        }
        @media only screen and (min-width: $screen-md) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
    @media only screen and (max-width: $screen-xs-max) {
        &__list-item {
            margin-top: $grid-gap;
            &:first-child {
                margin-top: 0;
            }
        }
    }

    .no-cssgrid & {
        &__list {
            display: -ms-grid;
            margin: (0 - ($grid-gap / 2));
            -ms-grid-columns: 1fr 1fr 1fr 1fr;
            -ms-grid-rows: (220px + $grid-gap);
            // -ms-grid-rows: (220px + $grid-gap) (220px + $grid-gap) (220px + $grid-gap);
        }
        &__list-item {
            height: 220px;
            margin: ($grid-gap / 2);
            &:nth-child(1) {
                -ms-grid-row: 1;
                -ms-grid-column: 1;
            }
            &:nth-child(2) {
                -ms-grid-row: 1;
                -ms-grid-column: 2;
            }
            &:nth-child(3) {
                -ms-grid-row: 1;
                -ms-grid-column: 3;
            }
            &:nth-child(4) {
                -ms-grid-row: 1;
                -ms-grid-column: 4;
            }
            &:nth-child(5) {
                -ms-grid-row: 2;
                -ms-grid-column: 1;
            }
            &:nth-child(6) {
                -ms-grid-row: 2;
                -ms-grid-column: 2;
            }
            &:nth-child(7) {
                -ms-grid-row: 2;
                -ms-grid-column: 3;
            }
            &:nth-child(8) {
                -ms-grid-row: 2;
                -ms-grid-column: 4;
            }
            &:nth-child(9) {
                -ms-grid-row: 3;
                -ms-grid-column: 1;
            }
            &:nth-child(10) {
                -ms-grid-row: 3;
                -ms-grid-column: 2;
            }
            &:nth-child(11) {
                -ms-grid-row: 3;
                -ms-grid-column: 3;
            }
            &:nth-child(12) {
                -ms-grid-row: 3;
                -ms-grid-column: 4;
            }
            &:nth-child(13) {
                -ms-grid-row: 4;
                -ms-grid-column: 1;
            }
            &:nth-child(14) {
                -ms-grid-row: 4;
                -ms-grid-column: 2;
            }
            &:nth-child(15) {
                -ms-grid-row: 4;
                -ms-grid-column: 3;
            }
            &:nth-child(16) {
                -ms-grid-row: 4;
                -ms-grid-column: 4;
            }
            &:nth-child(17) {
                -ms-grid-row: 5;
                -ms-grid-column: 1;
            }
            &:nth-child(18) {
                -ms-grid-row: 5;
                -ms-grid-column: 2;
            }
            &:nth-child(19) {
                -ms-grid-row: 5;
                -ms-grid-column: 3;
            }
            &:nth-child(20) {
                -ms-grid-row: 5;
                -ms-grid-column: 4;
            }
            &:nth-child(21) {
                -ms-grid-row: 6;
                -ms-grid-column: 1;
            }
            &:nth-child(22) {
                -ms-grid-row: 6;
                -ms-grid-column: 2;
            }
            &:nth-child(23) {
                -ms-grid-row: 6;
                -ms-grid-column: 3;
            }
            &:nth-child(24) {
                -ms-grid-row: 6;
                -ms-grid-column: 4;
            }
        }
        &__text-container {
            max-width: 100%;
        }
    }
    &__link--full-image,
    &__list-item .landing-tiles__link {
        /* START this block of CSS is also used just below */
        .landing-tiles__image-container {
            height: 100%;
            &:before {
                background-image: linear-gradient(to bottom, transparent, rgba($black, 0.94));
                bottom: 0;
                content: '';
                display: block;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 2;
            }
            & + .landing-tiles__text-container {
                bottom: 0;
                color: $white;
                left: 0;
                position: absolute;
                z-index: 4;
            }
        }
        &.landing-tiles__link--text-at-start .landing-tiles__image-container {
            &:before {
                background-image: linear-gradient(to top, transparent, rgba($black, 0.94));
            }
            & + .landing-tiles__text-container {
                bottom: auto;
                top: 0;
            }
        }
        /* END this block of CSS is also used just below */
    }

    //position based blu background (to be used instead of applying additional class
    //&__list-item:nth-child(3n+0) .landing-tiles__link {
    //    background-color: $blue;
    //    border: none;
    //    color: $white;
    //    &:hover,
    //    &:focus {
    //        color: $white;
    //    }
    //    .landing-tiles__heading {
    //        border-image: linear-gradient(to right, $white 0%, $white calc(100% - 30px), transparent calc(100% - 30px), transparent 100%);
    //        border-image-slice: 1;
    //    }
    //    .landing-tiles__link-arrow {
    //        color: $white;
    //    }
    //}



    &__link {
        border: 1px solid rgba($grey, 0.3);
        color: $darkgrey;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        overflow: hidden;
        position: relative;
        &:hover,
        &:focus {
            color: $darkgrey;
            text-decoration: none;
            .landing-tiles__link-arrow {
                margin-left: 20px;
            }
        }
        @media only screen and (max-width: $screen-xs-max) {
            min-height: 220px;
            /* START this block of CSS is also used just above */
            .landing-tiles__image-container {
                height: 100%;
                &:before {
                    background-image: linear-gradient(to bottom, transparent, rgba($black, 0.94));
                    bottom: 0;
                    content: '';
                    display: block;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: 2;
                }
                & + .landing-tiles__text-container {
                    bottom: 0;
                    color: $white;
                    left: 0;
                    position: absolute;
                    z-index: 4;
                }
            }
            &.landing-tiles__link--text-at-start .landing-tiles__image-container {
                &:before {
                    background-image: linear-gradient(to top, transparent, rgba($black, 0.94));
                }
                & + .landing-tiles__text-container {
                    bottom: auto;
                    top: 0;
                }
            }
            /* END this block of CSS is also used just above */
            // The following generates a triple .landing-tiles__link rule to override other CSS without using !important
            &.landing-tiles__link.landing-tiles__link {
                .landing-tiles__image-container {
                    position: absolute;
                    &:before {
                        background-color: rgba($black, 0.6667);
                        background-image: none;
                    }
                    & + .landing-tiles__text-container {
                        position: relative;
                    }
                }
            }
        }
        @media only screen and (min-width: $screen-sm) {
            align-items: flex-end;
        }
    }
    &__link:nth-child(3n+2) {

    }
    &__image-container {
        position: relative;
        display: block;
        height: calc(50% + #{$grid-gap / 2});
        width: 100%;
        overflow: hidden;
    }
    &__image {
        height: 100%;
        object-fit: cover;
        position: absolute;
        width: 100%;
        .no-object-fit & {
            left: 50%;
            max-width: none;
            min-width: 100%;
            transform: translateX(-50%);
            width: auto;
        }
    }
    &__text-container {
        margin-top: auto;
        padding: 15px;
        width: 100%;
    }
    &__heading {
        border-bottom-style: solid;
        border-image: linear-gradient(to right, $yellow 0%, $yellow calc(100% - 30px), transparent calc(100% - 30px), transparent 100%);
        border-image-slice: 1;
        border-width: 0 0 3px 0;
        font-size: 24px;
        font-weight: normal;
        margin: 0 0 15px;
        padding-bottom: 10px;
    }
    // &__text {
    //     font-size: 14px;
    //     min-height: 58px;
    // }
    &__link-arrow {
        color: $blue;
        transition: margin-left $material-motion;
    }
    &__link--blue-background {
        background-color: $blue;
        border: none;
        color: $white;
        &:hover,
        &:focus {
            color: $white;
        }
        .landing-tiles__heading {
            border-image: linear-gradient(to right, $white 0%, $white calc(100% - 30px), transparent calc(100% - 30px), transparent 100%);
            border-image-slice: 1;
        }
        .landing-tiles__link-arrow {
            color: $white;
        }
    }
    @media only screen and (min-width: $screen-xs) {}
    @media only screen and (min-width: $screen-sm) {}
    @media only screen and (min-width: $screen-md) {}
    @media only screen and (min-width: $screen-lg) {}
}
