/* Module: S */
.partnership-logos {
    padding: 60px 0;
    &__heading {
        font-size: 24px;
        font-weight: normal;
        margin: 0 0 20px;
        &:after {
            content: '…';
        }
    }
    &__container {
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    &__container--centered {
        margin-top: 30px;
    }
    &__img-wrapper {
        padding: 20px;
        margin: $grid-gutter-width 0 0 0;
        max-width: 200px;
        @media only screen and (max-width: $screen-xxs-max) {
            &:first-child {
                margin: 0;
            }
        }
    }
    &__img-wrapper--centered {
        max-width: 250px;
    }
    &__img {
        max-height: 100px;
    }
    @media only screen and (min-width: $screen-xs) {
        &__container {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
        }
        &__img-wrapper {
            margin: ($grid-gutter-width / 2);
        }
    }
    @media only screen and (min-width: $screen-xs) and (max-width: $screen-xs-max) {
        &__container--centered {
            margin-top: 0;
        }
    }
    @media only screen and (max-width: $screen-xs-max) {
        .no-flexboxlegacy &__img {
            max-width: 200px;
        }
        .no-flexboxlegacy &__img--centered {
            max-width: 250px;
        }
    }
    @media only screen and (min-width: $screen-sm) {
        &__container {
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            margin-left: ($grid-gutter-width / -2);
        }
        &__container--centered {
            justify-content: center;
            .no-flexboxlegacy & {
                justify-content: flex-start;
            }
        }
        &__img-wrapper {
            margin: 0 0 0 ($grid-gutter-width / 2);
            .no-flexboxlegacy & {
                flex-basis: 0px;
                flex-grow: 1;
            }
        }
    }
    @media only screen and (min-width: $screen-xs) {}
    @media only screen and (min-width: $screen-sm) {}
    @media only screen and (min-width: $screen-md) {}
    @media only screen and (min-width: $screen-lg) {}
}
