/* Module: S */
.footer {
    $local-darkgrey: #161717;
    background-color: $lightgrey;
    &__container {
        background-color: $local-darkgrey;
        padding: 30px 0;
    }
    &__useful-links-heading {
        color: $white;
        font-size: 16px;
        font-weight: 500;
        margin-top: 0;
    }
    &__useful-links-list {
        list-style: none;
        margin: 0 0 30px;
        padding: 0;
    }
    &__useful-links-list-link {
        color: $white;
        font-size: 14px;
        &:hover,
        &:focus {
            color: $white;
            text-decoration: none;
        }
    }
    &__sign-up-link {
        background-color: $darkgrey;
        box-shadow: 5px 5px $blue;
        display: block;
        margin: 0 5px 35px 0;
        padding: 15px;
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
    &__sign-up-heading {
        color: $blue;
        font-size: 24px;
        margin-top: 0;
    }
    &__sign-up-text {
        color: $white;
        font-size: 14px;
    }
    &__small-print-container {
        background-color: $darkgrey;
        display: flex;
        padding: 15px;
        @media only screen and (max-width: $screen-xs-max) {
            flex-direction: column-reverse;
        }
        @media only screen and (min-width: $screen-sm) {
            align-items: center;
        }
    }
    &__small-print-copyright {
        color: rgba($white, 0.7);
        font-size: 14px;
    }
    &__small-print--list {
        align-items: center;
        list-style: none;
        margin: 0 0 15px 0;
        padding: 0;
        @media only screen and (min-width: $screen-sm) {
            margin: 0 0 0 15px;
            display: flex;
        }
    }
    &__small-print--list-item + &__small-print--list-item {
        @media only screen and (max-width: $screen-xs-max) {
            border-top: solid 1px rgba($grey, 0.3);
        }
        @media only screen and (min-width: $screen-sm) {
            border-left: solid 1px rgba($grey, 0.3);
        }
    }
    &__small-print-list-link {
        color: rgba($white, 0.75);
        display: block;
        font-size: 14px;
        padding: 15px 0;
        &:hover,
        &:focus {
            color: rgba($white, 0.75);
            text-decoration: none;
        }
        @media only screen and (min-width: $screen-sm) {
            padding: 0 15px;
        }
    }
    @media only screen and (min-width: $screen-xs) {}
    @media only screen and (min-width: $screen-sm) {}
    @media only screen and (min-width: $screen-md) {}
    @media only screen and (min-width: $screen-lg) {}
}
