/* Module: S */
.homepage-carousel {
    height: 675px;
    @media only screen and (min-width: $screen-sm) {
        height: 875px;
    }
    &__carousel {
        height: 100%;
        list-style: none;
        margin: 0;
        padding: 0;
        .slick-list,
        .slick-track,
        .slick-slide {
            height: 100%;
        }
        .slick-dots {
            bottom: 30px;
            left: 50%;
            list-style: none;
            margin: 0;
            padding: 0;
            position: absolute;
            transform: translateX(-50%);
            li {
                display: inline-block;
                margin: 0 4px;
                button {
                    background-color: $white;
                    border: 0;
                    border-radius: 14px;
                    color: transparent;
                    cursor: pointer;
                    display: block;
                    font-size: 0;
                    height: 14px;
                    line-height: 0;
                    opacity: 0.25;
                    outline: none;
                    padding: 0;
                    transition: opacity $material-motion;
                    width: 14px;
                }
                &.slick-active button {
                    opacity: 1;
                }
            }
        }
    }
    &__carousel-item {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        // Used to be part of .homepage-carousel properties so it only needed to be rendered once, but there were z-ndex issues with the link text
        position: relative;
        &:before {
            background-image: linear-gradient(to bottom, rgba($black, 0.94), transparent);
            content: '';
            display: block;
            height: 500px;
            left: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 2;
        }
    }
    &__slide-wrapper {
        align-items: center;
        display: flex;
        height: 100%;
        padding: 200px 0 75px 0;
        @media only screen and (max-width: $screen-xs-max) {
            > .container {
                flex-grow: 1;
            }
        }
    }
    &__link {
        color: $white;
        display: block;
        padding-bottom: 100px;
        position: relative;
        z-index: 4;
        &:hover,
        &:focus {
            color: $white;
            text-decoration: none;
        }
    }
    &__heading {
        font-size: 34px;
        font-weight: 500;
        text-transform: uppercase;
        &:after {
            background-color: $blue;
            bottom: 0;
            border-radius: 25px;
            color: $black;
            content: attr(data-button-text);
            font-size: 16px;
            font-weight: normal;
            left: 0;
            line-height: 1;
            padding: 12px 22px;
            position: absolute;
            text-align: center;
            min-width: 180px;
        }
        @media only screen and (min-width: $screen-sm) {
            font-size: 64px;
            &:after {
                font-size: 18px;
                padding: 15px 25px;
                min-width: 200px;
            }
        }
    }
    @media only screen and (min-width: $screen-xs) {}
    @media only screen and (min-width: $screen-sm) {}
    @media only screen and (min-width: $screen-md) {}
    @media only screen and (min-width: $screen-lg) {}
}
