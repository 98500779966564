/* Module: S */

.button {
    background-color: $blue;
    color: $black;
    border-radius: 25px;
    // font-size: 18px;
    line-height: 1;
    font-weight: normal;
    text-align: center;
    padding: 12px 22px;
    min-width: 180px;
    text-transform: uppercase;
    &:hover,
    &:focus,
    &:active {
    	background: $blue;
    	color: $black;
    }
    @media only screen and (min-width: $screen-sm) {
        font-size: 18px;
        padding: 15px 25px;
        min-width: 200px;
    }
}

.form-inline {
	margin-top: -30px;
	.form-group {
		width: 100%;
		@include sq-flex-box;
		margin-bottom: 5px;
		label {
			width: 120px;
		}
		input {

		}
	}
}
