/* Module: S */
.site-select {

    $local-grey: #3f3f3f;
    $local-darkgrey: #2c2c2c;
    $local-lightgrey: #bbb;

    background-color: $local-darkgrey;
    &__list {
        border-left: rgba($local-lightgrey, 0.5) dashed 1px;
        list-style: none;
        margin: 0;
        overflow: hidden;
        padding: 0;
    }
    &__list-item {
        border-right: rgba($local-lightgrey, 0.5) dashed 1px;
        display: inline-block;
        float: left;
    }
    &__list-link {
        background-color: $local-grey;
        color: $local-lightgrey;
        display: inline-block;
        line-height: 30px;
        font-family: Verdana;
        font-size: pxToEm(12);
        font-weight: 500;
        padding: 0 10px;
        &--active {
            background-color: $local-darkgrey;
            color: $white;
        }
        &:hover,
        &:focus {
            color: $white;
            text-decoration: none;
        }
    }
    @media only screen and (min-width: $screen-xs) {}
    @media only screen and (min-width: $screen-sm) {}
    @media only screen and (min-width: $screen-md) {}
    @media only screen and (min-width: $screen-lg) {}
}
