/* Module: S */
.header {
    position: absolute;
    width: 100%;
    z-index: 6;
    &__container {
        display: flex;
        min-height: 130px;
        justify-content: space-between;
        .no-flexboxlegacy & {
            height: 130px;
        }
        @media only screen and (max-width: $screen-sm-max) {
            margin: 20px 0 0 0;
        }
        @media only screen and (min-width: $screen-md) {
            align-items: center;
        }
    }
    &__logo-link {
        display: inline-block;
    }
    // &__title {
    //     color: $white;
    //     font-size: 24px;
    //     font-weight: 700;
    //     margin: 0 0 0 20px;
    //     text-transform: uppercase;
    //     @media only screen and (max-width: $screen-xs-max) {
    //         display: block;
    //         margin: 20px 0 0 0;
    //     }
    // }
    &__nav-button {
        background: none;
        border: none;
        height: 24px;
        padding: 0;
        position: relative;
        // Gives the button a bigger touch area
        &:before {
            content: '';
            height: 42px;
            left: -7px;
            position: absolute;
            top: -2px;
            width: 36px;
        }
        &:after {
            color: white;
            content: 'Menu';
            font-size: 10px;
            font-weight: 500;
            left: -5px;
            line-height: 1.6;
            position: absolute;
            text-align: center;
            text-transform: uppercase;
            top: 100%;
            width: 32px;
        }
        &:focus {
            background: black;
            outline: 1px dotted;
            padding: 10px;
            color: white;
            height: 45px;
            overflow: hidden;
        }
    }
    &__nav-button-svg {
        fill: $white;
    }
    @media only screen and (min-width: $screen-xs) {}
    @media only screen and (min-width: $screen-sm) {}
    @media only screen and (min-width: $screen-md) {}
    @media only screen and (min-width: $screen-lg) {}
}

.skiptocontent {
    a {
        position: absolute;
        z-index: 100;
        padding: 10px;
        top: -40px;
        left: 60px;
        color: white;
        background:transparent;
        @include sq-transition(top 1s ease-out, background 1s linear);

        &:focus {
            position:absolute;
            left: 60px;
            top: 0  ;
            background: $blue;
            @include sq-transition(top .1s ease-in, background .5s linear);
        }
    }
}
