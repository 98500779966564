/* Module: S */
.made-in-links {
    .container {
        @media only screen and (max-width: $screen-xs-max) {
            width: 500px;
        }
        @media only screen and (max-width: $screen-film-poster-list-max) {
            width: 250px;
        }
    }
    $grid-gap: 25px;
    padding: 60px 0;
    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: grid;
        grid-gap: $grid-gap;
        grid-template-columns: 220px;
        grid-auto-rows: 385px;
        @media only screen and (min-width: $screen-film-poster-list) {
            grid-template-columns: 1fr 1fr ;
        }
        @media only screen and (min-width: $screen-sm) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @media only screen and (min-width: $screen-md) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
    .no-cssgrid & {
        &__list {
            display: -ms-grid;
            margin: (0 - ($grid-gap / 2));
            -ms-grid-columns: 1fr 1fr 1fr 1fr;
            -ms-grid-rows: (385px + $grid-gap);
        }
        &__list-item {
            height: 385px;
            margin: ($grid-gap / 2);
            &:nth-child(1) {
                -ms-grid-row: 1;
                -ms-grid-column: 1;
            }
            &:nth-child(2) {
                -ms-grid-row: 1;
                -ms-grid-column: 2;
            }
            &:nth-child(3) {
                -ms-grid-row: 1;
                -ms-grid-column: 3;
            }
            &:nth-child(4) {
                -ms-grid-row: 1;
                -ms-grid-column: 4;
            }
            &:nth-child(5) {
                -ms-grid-row: 2;
                -ms-grid-column: 1;
            }
            &:nth-child(6) {
                -ms-grid-row: 2;
                -ms-grid-column: 2;
            }
            &:nth-child(7) {
                -ms-grid-row: 2;
                -ms-grid-column: 3;
            }
            &:nth-child(8) {
                -ms-grid-row: 2;
                -ms-grid-column: 4;
            }
            &:nth-child(9) {
                -ms-grid-row: 3;
                -ms-grid-column: 1;
            }
            &:nth-child(10) {
                -ms-grid-row: 3;
                -ms-grid-column: 2;
            }
            &:nth-child(11) {
                -ms-grid-row: 3;
                -ms-grid-column: 3;
            }
            &:nth-child(12) {
                -ms-grid-row: 3;
                -ms-grid-column: 4;
            }
        }
        &__text-container {
            max-width: 100%;
        }
    }
    &__list-item {
        position: relative;
        background: black;
    }
    &__link {
        align-items: flex-end;
        color: $white;
        &:hover, &:focus {
            text-decoration: none;
            color: $white;
            .made-in-links__link-arrow {
                margin-left: 20px;
            }
        }
    }
    &__image-container {
        position: relative;
        display: block;
        height: 84%; //calc(70% + 12.5px);
        width: 100%;
        overflow: hidden;
    }
    &__image {
        height: 100%;
        object-fit: cover;
        position: absolute;
        width: 100%;
        .no-object-fit & {
            left: 50%;
            max-width: none;
            min-width: 100%;
            transform: translateX(-50%);
            width: auto;
        }
    }
    &__text-container {
        margin-top: auto;
        padding: 35px 15px 15px 15px;
        position: absolute;
        bottom: 0;
        width: 100%;
        background-image: linear-gradient(to top, rgba($black, 0.94),rgba($black, 0.94), transparent);
    }
    &__heading {
        border-bottom-style: solid;
        border-image: linear-gradient(to right, $yellow 0%, $yellow calc(100% - 30px), transparent calc(100% - 30px), transparent 100%);
        border-image-slice: 1;
        border-width: 0 0 3px 0;
        font-size: 24px;
        font-weight: normal;
        margin: 0 0 10px;
        padding-bottom: 10px;
    }
    &__link-arrow {
        color: $blue;
        margin-left: 0px;
        transition: margin-left $material-motion;
    }
    &__pagination {
        list-style: none;
        padding: 50px 0 0 0;
        font-size: 20px;
        font-weight: bold;
        line-height: 1.15;
        color: $yellow;
        text-align: center;
        li {
            min-width: 20px;
            display: inline-block;
            a {
                position: relative;
                color: $darkgrey;
                font-weight: normal;
                border-bottom: 3px solid transparent;
                &:hover, &:focus {
                    text-decoration: none;
                    border-bottom: 3px solid $yellow;
                }
            }
            &.pagination_next {
                position: relative;
                .landing-news__link-arrow {
                    font-size: 12px;
                    bottom: 6px;
                    color: #3ab5e1;
                    pointer-events: none;
                    position: absolute;
                    margin-right: 20px;
                    right: -38px;
                    transition: margin-right 0.2s cubic-bezier(0.4, 0, 0.2, 1);
                }
                &:hover, &:focus {
                    .landing-news__link-arrow {
                        margin-right: 0px;
                    }
                }
            }
            &.pagination_prev {
                position: relative;
                //margin-right: 20px;
                .landing-news__link-arrow {
                    font-size: 12px;
                    bottom: 6px;
                    color: #3ab5e1;
                    pointer-events: none;
                    position: absolute;
                    margin-left: 20px;
                    left: -38px;
                    transition: margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
                }
                &:hover, &:focus {
                    .landing-news__link-arrow {
                        margin-left: 0px;
                    }
                }
            }
        }
    }
    @media only screen and (min-width: $screen-xs) {
    }
    @media only screen and (min-width: $screen-sm) {
    }
    @media only screen and (min-width: $screen-md) {
    }
    @media only screen and (min-width: $screen-lg) {
    }
}
